import spinnerPath from 'images/spinner.gif';

var CC = {};

window.CC = CC;

CC.config = {
  apiBaseUrl: null
};

//
// browser
//
CC.browser = {};
CC.browser.transitionEvent = (function transitionEvent(){
    var t,
        el = document.createElement('fakeItTillYouMakeIt'),
        transitions = {
          'transition':'transitionend',
          'OTransition':'oTransitionEnd',
          'MozTransition':'transitionend',
          'WebkitTransition':'webkitTransitionEnd'
        };
    for(t in transitions){
      if( el.style[t] !== undefined ){
        return transitions[t];
      }
    }
})();

CC.browser.userAgent = navigator.userAgent;

// basic, I know, but good enough for what we need
// FF behaves badly when dealing with SVG + many elements
// we use this to check and modify logic where needed
CC.browser.isFireFox = (function() {
  return /Firefox[\/\s](\d+\.\d+)/.test(CC.browser.userAgent);
})();

//
// utils
//
CC.utils = {};

CC.utils.getIdFromString = function(str) {
  return parseInt(str.replace(/\D/g, ''), 10);
};

CC.utils.getMapStoreCollection = function() {
  return new CC.utils.mapStoreCollection();
};
CC.utils.mapStoreCollection = function() {
  this._data = [];
};
CC.utils.mapStoreCollection.prototype = {
  get: function(id) {
    return _.clone(_.find(this._data, {id:id}));
  },
  set: function(id, key, val) {
    _.find(this._data, {id:id})[key] = val;
    return this.get(id);
  },
  add: function(id, data) {
    data.id = id;
    this._data.push(data);
    return this.get(id);
  }
};

CC.utils.updateSpinner = function(params) {
  params.svg.selectAll('.spinner')
    .data(params.loading).enter()
    .append('image')
    .attr('class', 'spinner')
    .attr('height', 16)
    .attr('width', 16)
    .attr('style', 'z-index: 9999')
    .attr('x', params.width / 2 - 8)
    .attr('y', params.height / 2 - 8)
    .attr('xlink:href', spinnerPath);

  params.svg.selectAll('.spinner')
    .data(params.loading).exit()
    .remove();
};


CC.utils.addRenderingData = function(coll, params, fns) {
  return _.map(coll, function(item) {
    var innerItem = _.clone(item);
    _.each(fns, function(fn, idx) {
      innerItem = fn.call(null, innerItem, params);
    });
    return innerItem;
  });
};

CC.utils.addOverrideData = function (item, params) {
  var id = +item.properties.id,
      overrides;
  params.featureCollection.createIfNotExists(id.toString());
  overrides = params.featureCollection.get(id.toString()).get('coordinates');
  if (overrides) {
    item.coordinates = overrides;
  }
  return item;
};

CC.utils.addCentroidData = function(item, params) {
  item.properties.centroid = params.path.centroid(item);
  return item;
};

CC.utils.addTextTransformData = function(item, params) {
  var id = +item.properties.id,
      translate = [item.properties.centroid[0], item.properties.centroid[1]],
      textParams = params.featureCollection.get(id.toString()).get('textParams'),
      textTransform;
  if(_.isEmpty(textParams._dataRaw)) {
    if(window.location.pathname === '/coalitions/locations/') {
      textTransform = textParams.getTextTransform(translate, [10, 18]);
    } else {
      textTransform = textParams.getTextTransform(translate, [-15, 15]);
    }
  } else {
    // used for the NPS map at /national-parks
    textTransform = textParams.getTextTransform(translate);
  }
  item.properties.textTransformBox = textParams.getTextTransform(translate);
  item.properties.textTransform = textTransform;
  return item;
};









