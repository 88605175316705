require('international_address');

function closeNewsEvent() {
  var videourl = $('.news-events .active').find('iframe').attr('src');
  if (videourl != null) {
    var video = $('.news-events .active').find('iframe');
    $(video).attr('src','').attr('src',videourl);
  }
  $('.news-events .active').removeClass('active').find('.details').hide();
}

function openNewsEvent(item) {
  if (!$(item).find('.details').is(":visible")) {
    closeNewsEvent();
    $(item).addClass('active').find('.details').show();
  }
  var newsEventId = $(item).attr('id');
  window.location.hash = '#' + newsEventId;
}

$('.news-events .list-item').on("click", function() {
  openNewsEvent(this);
});

window.newsEventsSearch = function newsEventsSearch() {
  $('#search').trigger("keypress", function() {
    $(".search-x").show();
  });

  if ($('#search').val().length > "0") {
    $(".search-x").show();
  }

  $(".search-x").on("click", function(){
    $("#search").val("");
    $(".search-x").hide();
  });
}

window.carouselToolbox = function carouselToolbox() {
  $('.carousel .item').each(function(){
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));

    for (var i=0;i<0;i++) {
      next=next.next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
    }
  });
}
