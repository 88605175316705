(function(){

  // calculate input on form part 1
  $('.calculate-input').change(function () {
    var pubId = $(this).prev().attr('value');
    var sum = 0;

    $('.calculate-input').each(function() {
      if ($(this).prev().attr('value') == pubId) {
        sum += Number($(this).val());
      }
    });
    $('.calculate-total').each(function() {
      if ($(this).prev().attr('value') == pubId) {
        $(this).text(sum);
      }
    });
  });

  // bootstrap datepicker
  $(function() {
    $( "#datepicker" ).datepicker({
    });
  });

  // show category upon click until it is clicked again
  $("table.form-table1").hide();
  var count = 0;
  $("input.calculate-input").each(function(i, object) {
    if ($(object).val().length != 0) {
      count += 1;
    }
  });
  if(count == 0) {
    $("table.form-table1").first().show();
  } else {
    checkRequestedField();
  }
  $('h3.pub-category').click(function() {
    $(this).next().toggle();
      var pubOpener = $(this).find(".pub-opener").find("span")
      if(pubOpener.hasClass("fa-plus")) {
        pubOpener.addClass("fa-minus");
        pubOpener.removeClass("fa-plus");
      } else {
        pubOpener.addClass("fa-plus");
        pubOpener.removeClass("fa-minus");
      }
  });

  // check if requested field is filled out
  function checkRequestedField() {
    $("table.form-table1").hide();
    $("input.calculate-input").each(function(i, object) {
      if ($(object).val().length > 0) {
        $(object).closest('table.form-table1').show();
      }
    });
  }

  // open category table if error present
  if ($(".alert-form-error")[0]){
    checkRequestedField();
  }

  // jQuery validation plugin
  $(".edit_order").validate({
    rules: {
      "order[name]": {
        required: true
      },
      "order[street_address]": {
        required: true
      },
      "order[city]": {
        required: true
      },
      "order[state]": {
        required: true
      },
      "order[zip]": {
        required: true,
        zipcodeUS: true
      },
      "order[phone]": {
        required: true,
        phoneUS: true
      },
      "order[email]": {
        required: true,
        email: true
      },
      "order[need_by_date]": {
        required: true,
        dateFormat: true
      }
    },
    messages: {
      "order[name]": "Name is required.",
      "order[street_address]": "Address is required.",
      "order[city]": "City is required.",
      "order[state]": "State is required.",
      "order[zip]": {
        required: "ZIP code is required.",
        zipcodeUS: "ZIP code must contain five digits."
      },
      "order[phone]": {
        required: "Phone number is required.",
        phoneUS: "Please enter a valid phone number."
      },
      "order[email]": {
        required: "A valid email address is required.",
        email: "Please enter a valid email address."
      },
      "order[need_by_date]": {
        required: "Date needed is required.",
        dateFormat: "Date must be entered as mm/dd/yyyy"
      }
    }
  });

  $.validator.addMethod("phoneUS", function(phone_number, element) {
    phone_number = phone_number.replace(/\s+/g, "");
    return this.optional(element) || phone_number.length > 9 &&
      phone_number.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}$/);
  }, "Please specify a valid phone number");

  $.validator.addMethod("zipcodeUS", function(value, element) {
    return this.optional(element) || /^\d{5}(-\d{4})?$/.test(value);
  }, "The specified US ZIP Code is invalid");

  $.validator.addMethod("dateFormat", function(value, element) {
  return this.optional(element) || /\d{1,2}\/\d{1,2}\/\d{4}/.test(value);
}, "Date must be entered as mm/dd/yyyy.");
})();



