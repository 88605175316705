// open and close news items
  $('.ncfp-news-item').click(function(event){
    $(this).next().slideToggle(function() {
    });
    $(this).find(".play-toggle").toggleClass( "triangle-down", "play-toggle" );
  });

  $('#project-search-submit').click(function (e) {
    $(this).parents('form').submit();
  });
