window.toggleBio = function toggleBio() {
  $('.bio-box').each(function() {
    bio = $(this).parent().next();
      $(this).removeClass('active');
      $(bio).hide();
  })

  $('.bio-box').on("click", function(){
    bio = $(this).parent().next();
    if ($(bio).is(':visible')) {
      $(this).removeClass('active');
      $(bio).hide();
    } else {
      $('.bio-box').removeClass('active');
      $('.bio-expanded').hide();
      $(this).addClass('active');
      $(bio).show();
    }
  });
}

function resizeBios(section) {
  $(section).find('.bio').css('height', '');
  var maxHeight = -1;
  $(section).find('.bio').each(function() {
    maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
  });
  $(section).find('.bio').each(function() {
    $(this).height(maxHeight);
  });
}

window.uniformBios = function uniformBios() {
  $(window).on('load', function() {
    resizeBios($('.card-collapse:first-of-type'));
  });
  $(window).on('resize', function(){
    resizeBios($('.card-collapse:visible'));
  });
  $('.card-collapse').on('shown.bs.collapse', function(){
    resizeBios(this);
  });
}
