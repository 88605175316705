window.internationalAddress = function internationalAddress() {
  $('#show_international_address').click(function() {
    $('#international_address, .us_address').toggleClass('hide');
    if ($(this).prop('checked')) {
      $('.us_address input, .us_address select').val('international');
      $('#event_attendee_zip').val('00000');
    } else {
      $('.us_address input, .us_address select, #international_address input').val('');
    }
  });

  if ($('#event_attendee_state').val() == 'international') {
    $('#show_international_address').click();
  }

  $('#event_attendee_state').click(function() {
    if ($(this).val() == 'international') {
      $('#show_international_address').click();
    }
  });
}
