// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('bootstrap-datepicker');
require('highcharts');
require('cc');
require('jquery-validation');
require('jquery.url-param');
require('publication_orders');
require('news_events');
require('coalitions/search');
require('contact_bios');
require('projects_and_partnerships/projects');
require('projects_and_partnerships/initiatives');

// jump to an ID/click button when hash included in URL
window.clickHash = function clickHash() {
  if (window.location.hash) {
    $(window.location.hash).trigger("click");
  }
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
