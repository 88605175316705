require('stop_video');

$('.project-row').on("click", function(event){
  $(this).toggleClass("selected-header-color");
  $(this).next().addClass("selected-project-color").slideToggle(function() {
  });
  window.location.hash = "#" + this.id;
  return false
});

$("#project-select-option").on("change", function() {
  var query_param = $("#project-select-option").val();
  var url = '/projects/search?utf8=✓&project_search='+query_param;
  window.location.href = url;
});

$(function () {
  $('.project-logo').on("mouseenter", function () {
    $(this).find('.fleet-hover-text').show();
  }).on('mouseleave', function () {
    $(this).find('.fleet-hover-text').hide();
  });
});
